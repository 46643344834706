import {Map, View, Overlay, Feature} from 'ol';
import {Tile,Vector as VectorLayer} from 'ol/layer.js';
import {defaults as DefaultsControl, Control} from 'ol/control.js';
import {defaults as DefaultsInteraction, MouseWheelZoom, DragPan} from 'ol/interaction.js';
import {Style, Icon} from 'ol/style.js';
import {XYZ,Vector as VectorSource} from 'ol/source.js';
import {fromLonLat, transform, transformExtent} from 'ol/proj.js';
import { Point } from 'ol/geom.js';
import {platformModifierKeyOnly} from 'ol/events/condition.js'

const ol = {
    control: {
        Control, defaults: DefaultsControl
    },
    proj: {
        fromLonLat, transform, transformExtent
    },
    interaction: {
        defaults: DefaultsInteraction, MouseWheelZoom, DragPan
    },
    geom: {
        Point
    },
    layer: {
        Tile, Vector: VectorLayer
    },
    source: {
        XYZ, Vector: VectorSource
    },
    style: {
        Style, Icon
    },
    events: {
        condition: {
            platformModifierKeyOnly : platformModifierKeyOnly
        }
    },
    Map, View, Overlay, Feature
};

export default ol;